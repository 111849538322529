*, :before, :after {
  box-sizing: border-box;
}

body {
  --background: #fff;
  --blue: #0e407b;
  --blue-text: var(--blue);
  --gray-text: #595959;
  --text: #000;
  --ui-gray: #ddd;
  --monospace: ui-monospace, "Cascadia Code", Menlo, Consolas, monospace;
  background: var(--background);
  color: var(--text);
  margin: 0;
  font-family: ui-sans-serif, system-ui, sans-serif;
  font-size: 1.2em;
}

@media (prefers-color-scheme: dark) {
  body {
    --background: #000;
    --blue-text: #298ae8;
    --gray-text: #969696;
    --ui-gray: #4a4a4a;
    --text: #fff;
  }

  a {
    color: #2c99ff;
  }

  a:visited {
    color: #cb6ef0;
  }

  a:active {
    color: red;
  }
}

header, main, footer {
  max-width: 70rem;
  margin: 0 auto;
}

p {
  line-height: 1.68;
}

code {
  font-family: var(--monospace);
}

ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

header {
  padding: 0 .5rem 0 1rem;
}

header > div {
  border-right: .5rem solid var(--blue);
  padding-top: 2rem;
}

header h1, header .header-second-line {
  max-width: 50rem;
  margin: 0 auto;
}

header h1 {
  color: var(--blue-text);
  font-size: 3em;
}

header .header-second-line {
  align-items: baseline;
  display: flex;
}

header h2 {
  color: var(--gray-text);
  margin: 0;
}

header p {
  color: var(--gray-text);
  margin: 0;
  font-style: italic;
}

header p:before {
  content: "•";
  margin: 0 .5rem;
  font-style: normal;
}

main, footer {
  padding: 0 .5rem;
}

main > div {
  border: 0 solid #0000;
  border-bottom-width: .5rem;
  border-color: var(--blue);
  padding: 4rem .5rem;
}

main > div:last-child {
  border-bottom-color: #0000;
}

main > div p, main > div h3, main > div ul, main > div dl {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
}

main > div:nth-child(odd) {
  border-right-width: .5rem;
  padding-left: 1rem;
}

main > div:nth-child(2n) {
  border-left-width: .5rem;
  padding-right: 1rem;
}

main > div h3 {
  margin: 0 auto 1.5rem;
  font-size: 2rem;
}

main > div p:first-child {
  margin-top: 0;
}

main > div p:last-child {
  margin-bottom: 0;
}

main > div:last-child {
  margin-bottom: 3rem;
}

.projects {
  flex-direction: column;
  row-gap: 1.5rem;
  display: flex;
}

.projects a {
  border: .25rem solid var(--ui-gray);
  padding: 1rem;
  text-decoration: none;
  display: block;
}

.projects a:hover, .projects a:focus {
  border-color: color-mix(in srgb, var(--ui-gray), var(--text) 25%);
}

.projects h4 {
  margin: 0 0 1rem;
  font-size: 1.2rem;
  text-decoration: underline;
}

.projects p {
  color: var(--gray-text);
  margin: 0;
  font-size: 1rem;
  font-style: italic;
}

.contacts {
  margin-top: 0;
  margin-bottom: 0;
}

.contacts dt {
  color: var(--gray-text);
  font-weight: bold;
}

.contacts dd {
  margin: 0 0 3rem;
  font-size: 2em;
}

.contacts dd:last-child {
  margin-bottom: 0;
}

.contacts .email details {
  color: var(--gray-text);
  margin-top: .5rem;
  font-size: 1rem;
}

summary:hover {
  cursor: pointer;
}

.contacts .email p {
  margin: 0;
}

.contacts a {
  font-family: var(--monospace);
  font-variant-numeric: slashed-zero tabular-nums;
}

.contacts dd > a {
  text-decoration-color: var(--ui-gray);
}

.contacts dd > a:hover, .contacts dd > a:active {
  text-decoration-color: currentColor;
}

.contacts .email details p {
  padding-left: 1rem;
}

.other-links {
  flex-direction: column;
  row-gap: .75rem;
  display: flex;
}

.other-links li:before {
  content: "→";
  color: var(--gray-text);
  margin-right: .5rem;
}

footer p {
  color: var(--gray-text);
  max-width: 50rem;
  margin: 0 auto 1rem;
  font-size: 1rem;
}
/*# sourceMappingURL=index.fc482e73.css.map */
